import React, { useState } from "react"
import { Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from "@mui/material"
import { Grid } from "@mui/material"
import { FileYears, Year } from "../DocumentsPage/DocumentsPage/DocumentsPage"
import { StringArraySupportOption } from "prettier"

interface Option {
	id: number
	name: string
}

interface DropdownProps {
	options: Option[]
	width: string | number
	top?: string | number
	left?: string | number
	onSelect: (selectedOptions: Option[]) => void
	label: string
	firstValue?: string[]
	textTop?: string
	height?: string
	placeHolder?: string
	transformOrNot?: boolean
}

const Dropdown: React.FC<DropdownProps> = ({ options, width, top, left, onSelect, label, firstValue = [], textTop, height, placeHolder, transformOrNot }) => {
	const [selectedValues, setSelectedValues] = useState<string[]>(firstValue)

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const selected: any = event.target.value as string[]
		setSelectedValues(selected)
		// Lähetetään valitut vaihtoehdot parent-komponentille
		const selectedOptions = options.filter((option: Option) => selected.includes(option.name))
		onSelect(selectedOptions)
	}

	return (
		<FormControl fullWidth variant="outlined" sx={{ width: width, top: top, padding: "2px", bottom: "2px", borderRadius: "5px" }}>
			<InputLabel
				sx={{
					fontWeight: "500",
					fontSize: "0.9rem",
					left: left,
					top: textTop,
					transform: !transformOrNot ? "translate(17%, 35%) scale(0.75)" : "translate(15%, 60%) scale(1)",
					textAlign: "center",
					"&.MuiInputLabel-shrink": {
						transform: !transformOrNot ? "translate(17%, -10%) scale(0.75)" : "translate(20%, -10%) scale(0.75)"
					}
				}}
			>
				{label}
			</InputLabel>

			<Select
				multiple
				value={selectedValues}
				onChange={handleChange}
				label={label}
				displayEmpty
				renderValue={(selected: any) => {
					if (selected.length === 0) {
						return placeHolder ?? ""
					}
					if (selected.length === 1) {
						return selected[0]
					}
					return selected
						.map((name: string) => {
							const option = options.find((option) => option.name === name)
							return option ? option.name : ""
						})
						.filter((name: string) => name) // Removes any empty strings
						.join(", ")
				}} // Näytetään valitut arvot pilkuilla eroteltuna
				sx={{
					width: width,
					height: height,
					display: "flex",
					borderRadius: "10px",
					alignItems: "center", // Keskittää valitun arvon pystysuunnassa
					"& .MuiSelect-select": {
						paddingTop: "15px",
						paddingBottom: "10px",
						lineHeight: "normal",
						display: "flex",
						alignItems: "center"
					},
					"& .MuiOutlinedInput-notchedOutline": {
						top: 0
					}
				}}
			>
				{options.map((option: Option) => (
					<MenuItem key={option.id} value={option.name} sx={{ fontWeight: 100 }}>
						<Checkbox checked={selectedValues.indexOf(option.name) > -1} sx={{ margin: "-8px" }} />
						<Grid sx={{ paddingLeft: "25px" }}>{option.name}</Grid>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
{
	/* <ListItemText primary= fontWeight sx={{ paddingLeft: "30px" }} /> */
}
export default Dropdown
