import { Box, Dialog, DialogContent, IconButton, Typography } from "@mui/material"
import React, { SetStateAction, useEffect, useState } from "react"

import { ApolloQueryResult, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { CircularProgress, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField } from "@mui/material"
import { toast } from "react-toastify"

import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Chip, Divider, Stack } from "@mui/material"
import isMobile from "ismobilejs"
import { getAllCountries } from "../../../../../network/dataServices/generalDataService"
import CustomDialogActions from "../../../../reusables/CustomDialogActions"
import CustomDialogTitle from "../../../../reusables/CustomDialogTitle"
import DialogCloseButton from "../../../../reusables/DialogCloseButton"
import NotificationDialog from "../../../../reusables/NotificationDialog"
import authToken from "../../../../../utils/authToken"
import CustomNotification from "../../../../reusables/CustomNotification"
import CopyableTextField from "../../../../reusables/CopyableTextfield"

import IconLinkButton from "../../../../reusables/IconLinkButton"

const GET_USER_BILLINGINFO = gql`
	query GetUserBillingInfo($id: Int = 10) {
		roadCooperativeWithJWT {
			roadCooperativeMembersByRoadCooperativeId(condition: { id: $id }) {
				nodes {
					id
					name
					memberBillingInformationByMemberBillingInformationId {
						billingAddress
						businessId
						contactPerson
						id
						invoiceSendingType
						name
						meetingInviteType
						nodeId
						phoneNumber
						postalDistrict
						postcode
						email
						countryCode
						languageCode
						eInvoiceAddress
						eInvoiceOperator
					}
				}
			}
		}
	}
`

export const UPDATE_MEMBER_BILLINGINFO = gql`
	mutation UpdateMemberBillingInfo(
		$name: String = ""
		$from: String = ""
		$type: String = ""
		$memberId: Int = 10
		$billingAddress: String = ""
		$businessId: String = ""
		$contactPerson: String = ""
		$countryCode: String = ""
		$eInvoiceAddress: String = ""
		$eInvoiceOperator: String = ""
		$email: String = ""
		$id: Int = 10
		$invoiceSendingType: Int = 10
		$languageCode: String = ""
		$meetingInviteType: Int = 10
		$phoneNumber: String = ""
		$postalDistrict: String = ""
		$postcode: String = ""
	) {
		updateMemberUserNameAndBillingInfo(
			input: {
				name: $name
				from: $from
				type: $type
				memberId: $memberId
				memberBillingInformation: {
					billingAddress: $billingAddress
					businessId: $businessId
					contactPerson: $contactPerson
					countryCode: $countryCode
					eInvoiceAddress: $eInvoiceAddress
					eInvoiceOperator: $eInvoiceOperator
					email: $email
					id: $id
					invoiceSendingType: $invoiceSendingType
					languageCode: $languageCode
					meetingInviteType: $meetingInviteType
					phoneNumber: $phoneNumber
					postalDistrict: $postalDistrict
					postcode: $postcode
				}
			}
		) {
			updateSuccess
		}
	}
`

// const UPDATE_BILLINGINFO = gql`
// 	mutation UpdateBillingInfo(
// 		$id: Int = 10
// 		$businessId: String = ""
// 		$billingAddress: String = ""
// 		$contactPerson: String = ""
// 		$countryCode: String = ""
// 		$languageCode: String = ""
// 		$phoneNumber: String = ""
// 		$meetingInviteType: Int = 2
// 		$invoiceSendingType: Int = 2
// 		$postalDistrict: String = ""
// 		$postcode: String = ""
// 		$email: String = ""
// 		$eInvoiceAddress: String = ""
// 		$eInvoiceOperator: String = ""
// 	) {
// 		updateMemberUserNameAndBillingInfo(
// 			input: {
// 				memberBillingInformationPatch: {
// 					billingAddress: $billingAddress
// 					email: $email
// 					contactPerson: $contactPerson
// 					countryCode: $countryCode
// 					invoiceSendingType: $invoiceSendingType
// 					languageCode: $languageCode
// 					meetingInviteType: $meetingInviteType
// 					phoneNumber: $phoneNumber
// 					postalDistrict: $postalDistrict
// 					postcode: $postcode
// 					eInvoiceAddress: $eInvoiceAddress
// 					eInvoiceOperator: $eInvoiceOperator
// 					businessId: $businessId
// 				}
// 				id: $id
// 			}
// 		) {
// 			clientMutationId
// 		}
// 	}
// `

const DELETE_MEMBER = gql`
	mutation DeleteRoadCooperativeMember($id: Int = 10) {
		deleteRoadCooperativeMemberById(input: { id: $id }) {
			clientMutationId
		}
	}
`

const GET_MEMBER_ESTABLISHMENT_COUNT = gql`
	query GetMemberEstablishmentCount($id: Int!) {
		roadCooperativeMemberById(id: $id) {
			memberBillingInformationId
			establishmentsByRoadCooperativeMemberId {
				totalCount
				nodes {
					id
				}
			}
		}
		roadCooperativeWithJWT {
			contactPersonGeneral
			contactPersonFinance
		}
	}
`

type Partner = {
	name: string
	id: number
	userEmail: string
	allCostCenters: CostCenter[]
	establishments: Establishment[]
}

interface CustomBillingInfoType extends UserBillingInformationType {
	userEmail: string
	name?: string
}

const PartnerEditDialog = ({
	partner,
	setOpenEditDialog,
	refetch
}: {
	partner: Partner
	setOpenEditDialog: React.Dispatch<React.SetStateAction<boolean>>
	refetch: (
		variables?:
			| Partial<{
					id: number | null
			  }>
			| undefined
	) => Promise<ApolloQueryResult<any>>
}) => {
	const [partnerBillinginfo, setPartnerBillinginfo] = useState<CustomBillingInfoType>()
	const [memberName, setMemberName] = useState<string>("")
	const [allCountries, setAllCountries] = useState<Country[]>([])
	const [openDelete, setOpenDelete] = useState(false)
	const [openNameChangeNoti, setOpenNameChangeNoti] = useState(false)
	const [memberHasEstablishments, setMemberHasEstablishments] = useState(true)
	const [memberIsContantPerson, setMemberIsContactPerson] = useState(false)

	const [deleteMember] = useMutation(UPDATE_MEMBER_BILLINGINFO)
	const [updateBillingInfo] = useMutation(UPDATE_MEMBER_BILLINGINFO)
	const toastId = "infoToast"

	useEffect(() => {
		getAllCountries().then((data) => {
			if (data) setAllCountries(data)
		})
	}, [])

	const [getMemberEstablishmentCount, { data: memberEstCountData }] = useLazyQuery(GET_MEMBER_ESTABLISHMENT_COUNT, { variables: { id: partner.id } })
	const { data, loading } = useQuery(GET_USER_BILLINGINFO, {
		variables: {
			id: partner.id
		},
		onCompleted: (data) => {
			setPartnerBillinginfo({
				...data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes[0].memberBillingInformationByMemberBillingInformationId,
				userEmail: partner.userEmail
			})
			setMemberName(data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes[0].name)
		},
		onError(error) {
			console.log(error)
		}
	})

	const handleOpenConfirmDelete = async () => {
		const { data } = await getMemberEstablishmentCount()
		if (data.roadCooperativeMemberById.establishmentsByRoadCooperativeMemberId.totalCount != 0) {
			setMemberHasEstablishments(true)
		} else {
			setMemberHasEstablishments(false)
		}

		if (
			data.roadCooperativeMemberById.memberBillingInformationId == data.roadCooperativeWithJWT.contactPersonGeneral ||
			data.roadCooperativeMemberById.memberBillingInformationId == data.roadCooperativeWithJWT.contactPersonFinance
		) {
			setMemberIsContactPerson(true)
		}
		setOpenDelete(true)
	}

	const checkBillinginfoValidation = () => {
		let alertMessage = ""
		if (!partnerBillinginfo?.billingAddress) {
			alertMessage = alertMessage + "Postiosoite puuttuu. "
		}
		if (!partnerBillinginfo?.postalDistrict) {
			alertMessage = alertMessage + "Postitoimipaikka puuttuu. "
		}
		if (!partnerBillinginfo?.postcode) {
			alertMessage = alertMessage + "Postinumero puuttuu. "
		} else if (partnerBillinginfo.countryCode === "FI" ? partnerBillinginfo.postcode?.length > 5 : partnerBillinginfo.postcode?.length > 15) {
			alertMessage =
				alertMessage +
				`postinumero voi olla ${partnerBillinginfo.countryCode === "FI" ? 5 : 15} merkin pituinen maalle ${
					allCountries.find((country: any) => {
						return country.countryCode === partnerBillinginfo.countryCode
					})?.country
				}.`
		}
		if (alertMessage != "") {
			toast.warning(alertMessage, { role: "gloabl" })
			return false
		} else {
			return true
		}
	}

	const handleUpdateMember = () => {
		if (!checkBillinginfoValidation()) return
		updateBillingInfo({
			variables: {
				billingAddress: partnerBillinginfo?.billingAddress ?? "",
				businessId: partnerBillinginfo?.businessId ?? "",
				contactPerson: partnerBillinginfo?.contactPerson ?? "",
				countryCode: partnerBillinginfo?.countryCode ?? "",
				eInvoiceAddress: partnerBillinginfo?.eInvoiceAddress ?? "",
				eInvoiceOperator: partnerBillinginfo?.eInvoiceOperator ?? "",
				email: partnerBillinginfo?.email ?? "",
				id: partnerBillinginfo?.id,
				invoiceSendingType: Number(partnerBillinginfo?.invoiceSendingType),
				languageCode: partnerBillinginfo?.languageCode,
				meetingInviteType: Number(partnerBillinginfo?.meetingInviteType),
				phoneNumber: partnerBillinginfo?.phoneNumber ?? "",
				postalDistrict: partnerBillinginfo?.postalDistrict ?? "",
				postcode: partnerBillinginfo?.postcode ?? "",
				name: memberName ?? "",
				from: "homePage",
				type: "update"
			},
			onCompleted: () => {
				// toast.success("Osakkaan tiedot päivitetty", { role: "global" })
				refetch()
				setOpenEditDialog(false)
			},
			onError: () => {
				// toast.error("Tietojen päivitys epäonnistui", { role: "global" })
				null
			}
		})
	}

	const handleDeletePartner = () => {
		const userEmail = authToken.getDecodedToken()?.email
		if (!userEmail || userEmail == partner.userEmail) {
			toast.error("Et voi poistaa itseäsi tiekunnasta", { role: "global" })
			return
		}
		deleteMember({
			variables: { memberId: partner.id, from: "homePage", type: "delete", id: partnerBillinginfo?.id },
			onCompleted: () => {
				refetch()
			},
			onError: (error) => {
				console.error(error)
			}
		})
		setOpenEditDialog(false)
	}

	const handleClose = () => {
		setOpenEditDialog(false)
		toast.dismiss(toastId)
	}

	return (
		<Box sx={{ textAlign: "center" }}>
			{!partnerBillinginfo ? (
				<CircularProgress />
			) : (
				<Grid container rowSpacing={1} columnSpacing={2}>
					<Grid item xs={12} sm={6}>
						<CopyableTextField maxLength={100} label="Nimi" fullWidth onChange={(value: string) => setMemberName(value)} value={memberName} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							inputProps={{
								maxLength: 100
							}}
							fullWidth
							disabled
							onChange={(e: { target: { value: string } }) => setPartnerBillinginfo({ ...partnerBillinginfo, name: e.target.value })}
							label="Käyttäjätunnus"
							value={partnerBillinginfo.userEmail}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider
							sx={{
								margin: "5px"
							}}
						></Divider>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CopyableTextField
							maxLength={100}
							fullWidth
							onChange={(value) => setPartnerBillinginfo({ ...partnerBillinginfo, contactPerson: value })}
							label="Yhteyshenkilö"
							value={partnerBillinginfo.contactPerson}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CopyableTextField
							label="Postiosoite"
							fullWidth
							value={partnerBillinginfo.billingAddress}
							onChange={(value) => setPartnerBillinginfo({ ...partnerBillinginfo, billingAddress: value })}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CopyableTextField
							maxLength={20}
							label="Postinumero"
							fullWidth
							onChange={(value) => setPartnerBillinginfo({ ...partnerBillinginfo, postcode: value })}
							value={partnerBillinginfo.postcode}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CopyableTextField
							label="Postitoimipaikka"
							fullWidth
							onChange={(value) => setPartnerBillinginfo({ ...partnerBillinginfo, postalDistrict: value })}
							value={partnerBillinginfo.postalDistrict}
						/>
					</Grid>
					<Grid item xs={11} sm={5} sx={{ width: "80%" }}>
						<CopyableTextField
							maxLength={9}
							fullWidth
							onChange={(value) => setPartnerBillinginfo({ ...partnerBillinginfo, businessId: value })}
							value={partnerBillinginfo.businessId}
							label="Y-tunnus"
						/>
					</Grid>

					<Grid sx={{ paddingLeft: "3px", paddingTop: "20px" }}>
						<Box>
							<IconLinkButton
								url="https://tietopalvelu.ytj.fi/"
								title={
									<Typography sx={{ fontSize: "0.7rem", marginX: "2px", color: "white", fontWeight: "light" }}>
										Y-tunnus voi olla ainoastaan 9 merkkiä pitkä. Tarkista tarvittaessa oma y-tunnuksesi YTJ:n sivuilta klikkaamalla.
									</Typography>
								}
							></IconLinkButton>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CopyableTextField
							fullWidth
							maxLength={50}
							onChange={(value) => setPartnerBillinginfo({ ...partnerBillinginfo, phoneNumber: value })}
							label="Puhelinnumero"
							value={partnerBillinginfo.phoneNumber}
						/>
					</Grid>
					<Grid item xs={0} sm={2.5} />
					<Grid item xs={12} sm={7} sx={{ alignSelf: "center" }}>
						<CopyableTextField
							fullWidth
							label="Sähköposti (yhteydenpito)"
							onChange={(value) => setPartnerBillinginfo({ ...partnerBillinginfo, email: value })}
							value={partnerBillinginfo.email}
							maxLength={50}
						/>{" "}
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							label="Maa"
							onChange={(e: { target: { value: string } }) => setPartnerBillinginfo({ ...partnerBillinginfo, countryCode: e.target.value })}
							value={partnerBillinginfo.countryCode}
							SelectProps={{
								MenuProps: {
									style: {
										zIndex: 1305
									}
								}
							}}
							select
							fullWidth
						>
							{allCountries.map((country: Country) => {
								return (
									<MenuItem key={country.countryCode.toString()} value={country.countryCode}>
										{country.country} - {country.countryCode}
									</MenuItem>
								)
							})}
						</TextField>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							label="Kieli"
							SelectProps={{
								MenuProps: {
									style: {
										zIndex: 1305
									}
								}
							}}
							onChange={(e: { target: { value: string } }) => setPartnerBillinginfo({ ...partnerBillinginfo, languageCode: e.target.value })}
							value={partnerBillinginfo.languageCode}
							select
							fullWidth
						>
							<MenuItem value={"fi"}>Suomi</MenuItem>
							<MenuItem value={"sv"}>Ruotsi</MenuItem>
							<MenuItem value={"en"}>Englanti</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<Typography
							sx={{
								paddingTop: "20px"
							}}
						>
							Laskun toimitustapa
						</Typography>
						<RadioGroup
							row
							sx={{ justifyContent: "center" }}
							value={partnerBillinginfo.invoiceSendingType}
							onChange={(e: { target: { value: number } }) =>
								setPartnerBillinginfo({ ...partnerBillinginfo, invoiceSendingType: e.target.value })
							}
						>
							<FormControlLabel value={2} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Sähköposti</Typography>} />
							<FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Kirje</Typography>} />
							<FormControlLabel
								value={3}
								control={<Radio />}
								disabled={!partnerBillinginfo.businessId}
								label={<Typography sx={{ fontWeight: "500" }}>Verkkolasku</Typography>}
							/>
						</RadioGroup>
					</Grid>

					{partnerBillinginfo.invoiceSendingType == 3 && partnerBillinginfo.businessId ? (
						<Grid container rowSpacing={1} columnSpacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									inputProps={{
										maxLength: 30
									}}
									label="Verkkolaskuosoite"
									onChange={(e: { target: { value: string } }) =>
										setPartnerBillinginfo({ ...partnerBillinginfo, eInvoiceAddress: e.target.value })
									}
									fullWidth
									value={partnerBillinginfo.eInvoiceAddress}
									required
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									inputProps={{
										maxLength: 30
									}}
									label="Operaattoritunnus"
									onChange={(e: { target: { value: string } }) =>
										setPartnerBillinginfo({ ...partnerBillinginfo, eInvoiceOperator: e.target.value })
									}
									fullWidth
									value={partnerBillinginfo.eInvoiceOperator}
									required
								/>
							</Grid>
						</Grid>
					) : null}
					<Grid item xs={12}>
						<Typography
							sx={{
								paddingTop: "20px"
							}}
						>
							Kokouskutsun toimitustapa
						</Typography>
						<RadioGroup
							sx={{ justifyContent: "center" }}
							value={partnerBillinginfo.meetingInviteType}
							row
							onChange={(e: { target: { value: number } }) => setPartnerBillinginfo({ ...partnerBillinginfo, meetingInviteType: e.target.value })}
						>
							<FormControlLabel value={2} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Sähköposti</Typography>} />
							<FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Kirje</Typography>} />
						</RadioGroup>
					</Grid>
				</Grid>
			)}
			{isMobile().any ? (
				<IconButton
					onClick={() => {
						handleOpenConfirmDelete()
					}}
					icon={<DeleteIcon />}
					sx={{
						position: "absolute",
						top: "5px",
						left: "5px"
					}}
				>
					<DeleteIcon />
				</IconButton>
			) : (
				<Chip
					label="Poista"
					variant="contained"
					onClick={() => {
						handleOpenConfirmDelete()
					}}
					icon={<DeleteIcon />}
					sx={{
						position: "absolute",
						top: "10px",
						left: "10px"
					}}
				/>
			)}
			<Stack
				direction="row"
				sx={{
					position: "absolute",
					bottom: "25px",
					right: "25px",
					left: "25px",
					justifyContent: "space-between"
				}}
			>
				<Button variant="outlined" color="cancel" onClick={handleClose}>
					Sulje
				</Button>
				<Button color="primary" variant="contained" onClick={() => handleUpdateMember()}>
					Tallenna
				</Button>
			</Stack>

			<Dialog sx={{ zIndex: "1305" }} open={openDelete}>
				<DialogCloseButton closeFunction={() => setOpenDelete(false)} />
				<CustomDialogTitle>Poista osakas</CustomDialogTitle>
				<DialogContent>
					{memberHasEstablishments && (
						<CustomNotification severity="warning">
							Kyseisellä osakkaalla on yksiköitä. Et voi poistaa osakasta ennen kuin olet siirtänyt tai poistanut osakkaan yksiköt.{" "}
						</CustomNotification>
					)}
					{memberIsContantPerson && (
						<CustomNotification severity="warning">
							Osakas on tiekunnassa yhteyshenkilö. Vaihda asetuksista yhteyshenkilöksi eri osakas mikäli haluat poistaa osakkaan tiekunnasta.
						</CustomNotification>
					)}
					<Typography>Haluatko varmasti poistaa osakkaan tiekunnasta?</Typography>
				</DialogContent>
				<CustomDialogActions>
					<Button
						variant="outlined"
						color="cancel"
						onClick={() => {
							setOpenDelete(false)
						}}
					>
						Peruuta
					</Button>
					<Button disabled={memberHasEstablishments || memberIsContantPerson} variant="contained" color="close" onClick={handleDeletePartner}>
						Poista
					</Button>
				</CustomDialogActions>
			</Dialog>
		</Box>
	)
}

export default PartnerEditDialog
