import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./SideNavBar.css"
import widelogo from "./download.svg"
import { useTranslation } from "react-i18next"
import ChatBot from "./ChatBot"
import NotesPage from "../NotesPage/NotesPage"

// Icon imports here
import homeIcon from "../../resources/navBarLogos/tievahti-gui-icons-koti.png"
import financesIcon from "../../resources/navBarLogos/tievahti-gui-icons-talous.png"
// import   UnravelIcon from "../../resources/navBarLogos/"
import chatIcon from "../../resources/navBarLogos/tievahti-gui-icons-tuki.png"
import mapIcon from "../../resources/navBarLogos/tievahti-gui-icons-koti.png"
import meetingsIcon from "../../resources/navBarLogos/tievahti-gui-icons-kokoukset.png"
// import  RoadCooperativeIcon from "../../resources/navBarLogos/"
import profileIcon from "../../resources/navBarLogos/tievahti-gui-icons-profiili.png"
import settingsIcon from "../../resources/navBarLogos/tievahti-gui-icons-asetukset.png"
import architectureIcon from "../../resources/navBarLogos/tievahti-gui-icons-yksikointi.png"
import contactsIcon from "../../resources/navBarLogos/tievahti-gui-icons-yhteystiedot.png"
import notesIcon from "../../resources/navBarLogos/tievahti-gui-icons-muistio.png"
import GroupsIcon from "@mui/icons-material/Groups"
import documentIcon from "../../resources/navBarLogos/tievahti-gui-icons-asiakirjat.png"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import RoadCooperativeContacts from "../HomePage/RoadCooperativeContactsPage/RoadCooperativeContacts"
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import { ReactComponent as EnglishFlag } from "../../resources/icons/gb.svg"
import { ReactComponent as SwedishFlag } from "../../resources/icons/se.svg"
import { ReactComponent as FinnishFlag } from "../../resources/icons/fi.svg"
import authToken from "../../utils/authToken"
// Icon imports end

const SideNavBar = ({ permission }: any) => {
	const { t, i18n } = useTranslation()

	type LanguageOption = {
		[key: string]: {
			nativeName: string
		}
	}

	const lngs: LanguageOption = {
		en: { nativeName: "English" },
		fi: { nativeName: "Suomi" },
		sv: { nativeName: "Svenska" }
	}

	type MenuLinkProps = {
		name: string
		path: string
		onClick?: () => void
		customContent?: React.ReactNode
	}

	const iconStyle = { width: 40, height: 40 }

	const IconResolver = (name: string) => {
		switch (name) {
			case "home":
				return <img src={homeIcon} alt="Home" style={iconStyle} />
			case "finances":
				return <img src={financesIcon} alt="Finances" style={iconStyle} />
			// case "partnersInfo":
			// 	return <img src={groupsIcon} alt="Partners Info" style={{ width: 40, height: 40 }} />;
			// case "unravel":
			// 	return <img src={unravelIcon} alt="Unravel" />;
			case "chat":
				return <img src={chatIcon} alt="Chat" style={iconStyle} />
			case "map":
				return <img src={mapIcon} alt="Map" style={iconStyle} />
			case "meetings":
				return <img src={meetingsIcon} alt="Meetings" style={iconStyle} />
			case "document":
				return <img src={documentIcon} alt="Document" style={iconStyle} />
			// case "roadcooperative":
			// 	return <img src={roadCooperativeIcon} alt="Road Cooperative" />;
			case "profile":
				return <img src={profileIcon} alt="Profile" style={iconStyle} />
			case "settings":
				return <img src={settingsIcon} alt="Settings" style={iconStyle} />
			case "notes":
				return <img src={notesIcon} alt="Notes" style={iconStyle} />
			case "contacts":
				return <img src={contactsIcon} alt="Contacts" style={iconStyle} />
			case "unitizationTool":
				return <img src={architectureIcon} alt="Unitization Tool" style={iconStyle} />
			default:
				return null
		}
	}

	const MenuLink = (props: MenuLinkProps) => {
		return (
			<Link to={props.path} className="nav_link" id={props.name} style={{ textDecoration: "none" }}>
				{IconResolver(props.name)}
				<span className="nav_name">{t(`menu.${props.name}`)}</span>
			</Link>
		)
	}

	type flagProps = {
		language: string
	}

	const Flag = (props: flagProps) => {
		switch (props.language) {
			case "en":
				return <EnglishFlag />
			case "sv":
				return <SwedishFlag />
			case "fi":
				return <FinnishFlag />
			default:
				return null
		}
	}

	document.addEventListener("DOMContentLoaded", function () {
		const hover = document.getElementById("nav-bar")

		// console.log(hover)

		/*===== LINK ACTIVE =====*/
		const linkColor = document.querySelectorAll(".nav_link")

		linkColor.forEach((l) =>
			l.addEventListener("click", () => {
				linkColor.forEach((item) => item.classList.remove("active"))
				l.classList.add("active")
				// console.log(linkColor);
				window.localStorage.setItem("linksColor", l.id)

				// console.log(window.localStorage.getItem('linksColor'))
				// console.log(active);
			})
		)

		if (window.localStorage.getItem("linksColor")) {
			const item: string = window.localStorage.getItem("linksColor") ?? ""
			document.getElementById(item)
			// console.log(item, "  -------------- item ----------");

			return
		}

		// Your code to run since DOM is loaded and ready
	})
	///
	const [openChatDialog, setOpenChatDialog] = useState(false)
	const [openNotesDialog, setOpenNotesDialog] = useState(false)
	const [openRCContactsDialog, setOpenRCContactsDialog] = useState(false)

	///
	return (
		<div>
			<div className="l-navbar" id="nav-bar">
				<div className="nav_header">
					<a className="nav_logo nav_grid_item1 ">
						<img src={widelogo} height="85vh" />
						<span className="nav_logo-name"></span>
					</a>

					{/* <div className="nav_link" style={{ display: "none" }}>
						<FormControl>
							<Select
								labelId="lang_dropdown"
								id="lang_dropdown"
								value={i18n.resolvedLanguage}
								label="Kieli"
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => i18n.changeLanguage(e.target.value)}
								renderSuffix={() => <Flag language={i18n.resolvedLanguage} />}
							>
								{Object.keys(lngs).map((lng: string, i) => (
									<MenuItem key={i} value={lng}>
										{lngs[lng].nativeName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div> */}
				</div>
				<nav className="nav">
					<div className="nav_list">
						<MenuLink name="home" path="/" />
						{(permission === "edit" || permission === "municipality") && <MenuLink name="finances" path="/finances" />}
						{permission !== "municipality" && <MenuLink name="meetings" path="/calendar" />}
						<MenuLink name="document" path="/documents" />
						{authToken.getDecodedToken()?.email.includes("@tievahti.fi") && permission === "edit" && (
							<MenuLink name="unitizationTool" path="/unitization-tool" />
						)}
						<div>
							<div onClick={() => setOpenRCContactsDialog(!openRCContactsDialog)}>
								<MenuLink name="contacts" path="#" />
							</div>
						</div>
						<MenuLink name="settings" path="/options/general" />
					</div>

					<div className="nav_bottom_links">
						{permission !== "municipality" && (
							<>
								<div>
									<div onClick={() => setOpenChatDialog(!openChatDialog)}>
										<MenuLink name="chat" path="#" />
									</div>
								</div>

								<div>
									<div onClick={() => setOpenNotesDialog(!openNotesDialog)}>
										<MenuLink name="notes" path="#" />
									</div>
								</div>
							</>
						)}
						<MenuLink name="profile" path="/profile" />
					</div>
				</nav>
			</div>

			{openChatDialog ? <ChatBot setOpenChatDialog={setOpenChatDialog} openChatDialog={openChatDialog} /> : null}
			{openNotesDialog ? <NotesPage openNotesDialog={openNotesDialog} setOpenNotesDialog={setOpenNotesDialog} /> : null}
			{openRCContactsDialog ? (
				<RoadCooperativeContacts openRCContactsDialog={openRCContactsDialog} setOpenRCContactsDialog={setOpenRCContactsDialog} />
			) : null}
		</div>
	)
}

export default SideNavBar
