import maplibregl from "maplibre-gl"
import React, { createContext, useContext, useMemo, useReducer } from "react"

declare global {
	type FeatureType = "line" | "fill" | "circle" | "symbol"

	type UnitizationMapLayerType = {
		layerId: string
		sourceId: string
		featureType: FeatureType
		name: string
		visibility: "visible" | "none"
	}

	type Est = {
		additionalInformation: string
		amount: number
		discretionalyCorrectionMultiplier: number
		distanceCorrectionMultiplier: number
		id: string
		operatingDistance: number
		roadUsageUnitId: string
		unitizationPropertyId: string
	}

	type UnitizationMapProviderDispatch = (action: Action) => void

	type UnitizationRoadlink = {
		coordinates: any[]
		costCenter?: { id: string; name: string }
		linkId: string
		linkLength?: number
		id?: any
	}

	interface UnitizationProperty {
		plotId: any
		mmlPropertyId: string
		propertyName: string
		costCenter?: { id: string; name?: string; idColor?: string }
		id?: any
		coordinates: any[]
		propertyFeature?: any
		isUnitized?: boolean
	}

	interface ClickedUnitizationProperty extends UnitizationProperty {
		option: "view" | "add" | "delete"
	}
	interface ClickedUnitizationRoadlink extends UnitizationRoadlink {
		delete: boolean
	}

	interface UnitizationCostCenter {
		id: number
		name: string
		idColor: string
		establishments: Establishment[]
		properties: UnitizationProperty[]
		roadlinks: UnitizationRoadlink[]
	}

	type UnitizationActiveFeature = { selectRoadlinks: boolean; selectProperties: boolean; viewProperties: boolean; calculateRoadDistance: boolean }

	type UnitizationMapProviderStateType = {
		unitization: { name: string; id: undefined; descrpition?: string; unitizatioActivationTimeStamp?: any }
		allProperties?: UnitizationProperty[]
		roadlinks?: UnitizationRoadlink[]
		costCenters: UnitizationCostCenter[]
		mapLayers: UnitizationMapLayerType[] | []
		mapSources: MapSource[] | []
		currentPopup?: maplibregl.Popup
		activePropertyPlotId?: string
		activeFeatures: UnitizationActiveFeature
		activeCostCenter: any
		clickedProperty?: ClickedUnitizationProperty
		clickedRoadlink?: ClickedUnitizationRoadlink
		mapRef?: any
		setSelectedProperty?: any
		selectedProperty?: any
		routeDistance: number | null
	}
}

type MapSource = {
	source: string
	sourceLayer: string
}

type Action =
	| { type: "INITIAL"; payload: UnitizationMapProviderStateType }
	| { type: "setMapLayers"; payload: UnitizationMapLayerType[] | [] }
	| { type: "setMapSources"; payload: MapSource[] | [] }
	| { type: "setAllProperties"; payload: UnitizationProperty[] }
	| { type: "setAllRoadlinks"; payload: UnitizationRoadlink[] }
	| { type: "setCostCenters"; payload: any[] }
	| { type: "setActivePropertyPlotId"; payload: string | undefined }
	| { type: "setCurrentPopup"; payload: maplibregl.Popup | undefined }
	| { type: "setActiveFeatures"; payload: UnitizationActiveFeature }
	| { type: "setActiveCostCenter"; payload: any }
	| { type: "setClickedProperty"; payload: ClickedUnitizationProperty | undefined }
	| { type: "setClickedRoadlink"; payload: ClickedUnitizationRoadlink | undefined }
	| { type: "setMapRef"; payload: any }
	| { type: "setUnitizationDescription"; payload: string }
	| { type: "setUnitizationActivationTimeStamp"; payload: string }
	| { type: "setRouteDistance"; payload: number | null }

const DEFAULT_STATE: UnitizationMapProviderStateType = {
	unitization: { name: "", id: undefined },
	allProperties: [],
	roadlinks: [],
	costCenters: [],
	activeCostCenter: undefined,
	mapLayers: [],
	mapSources: [],
	activePropertyPlotId: undefined,
	currentPopup: undefined,
	activeFeatures: { selectRoadlinks: false, selectProperties: false, viewProperties: false, calculateRoadDistance: false },
	routeDistance: 0
}

const mapReducer = (state: UnitizationMapProviderStateType, action: Action) => {
	switch (action.type) {
		case "INITIAL":
			return { ...action.payload }
		case "setMapLayers":
			return { ...state, mapLayers: action.payload }
		case "setMapSources":
			return { ...state, mapFeatureStates: action.payload }
		case "setAllProperties":
			return { ...state, allProperties: action.payload }
		case "setAllRoadlinks":
			return { ...state, roadlinks: action.payload }
		case "setCostCenters":
			return { ...state, costCenters: action.payload }
		case "setActivePropertyPlotId":
			return { ...state, activePropertyPlotId: action.payload }
		case "setCurrentPopup":
			return { ...state, currentPopup: action.payload }
		case "setActiveFeatures":
			return { ...state, activeFeatures: action.payload }
		case "setActiveCostCenter":
			return { ...state, activeCostCenter: action.payload }
		case "setClickedProperty":
			return { ...state, clickedProperty: action.payload }
		case "setClickedRoadlink":
			return { ...state, clickedRoadlink: action.payload }
		case "setMapRef":
			return { ...state, mapRef: action.payload }
		case "setUnitizationDescription":
			state.unitization = { ...state.unitization, descrpition: action.payload }
			return state
		case "setUnitizationActivationTimeStamp":
			return { ...state, unitization: { ...state.unitization, unitizatioActivationTimeStamp: action.payload } }
		case "setRouteDistance":
			state.routeDistance = action.payload
			return state
	}
}

type MapProviderProps = { initialState?: UnitizationMapProviderStateType; children: React.ReactNode }

const MapStateContext = createContext<{ state: UnitizationMapProviderStateType; dispatch: UnitizationMapProviderDispatch } | undefined>(undefined)

const UnitizationMapStateProvider = ({ initialState, children }: MapProviderProps) => {
	const [state, dispatch] = useReducer(mapReducer, initialState ?? DEFAULT_STATE)
	const memoChildren = useMemo(() => children, [state, children])
	const value = { state, dispatch }
	return <MapStateContext.Provider value={value}>{memoChildren}</MapStateContext.Provider>
}

const useUnitizationMapState = () => {
	const context = useContext(MapStateContext)
	if (context === undefined) {
		throw new Error("useUnitizationMapState hook must be used within a UnitizationMapStateProvider")
	}
	return context
}

export { UnitizationMapStateProvider, useUnitizationMapState }
