import { ApolloQueryResult, gql, OperationVariables, useMutation, useQuery } from "@apollo/client"
import { TableContainer, TableHead, TableCell, Collapse, Box, Typography } from "@mui/material"
import {
	Paper,
	Table,
	TableRow,
	TableBody,
	IconButton,
	Dialog,
	TextField,
	MenuItem,
	Divider,
	Container,
	DialogContent,
	Grid,
	Tooltip,
	Button
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useCreateUserState } from "../../../utils/providers/CreateUserProvider"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import { toast } from "react-toastify"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import { InputAdornment } from "@mui/material"
import MonetaryInput from "../../../utils/MonetaryInput"
import propertiesSorting, { establishmentSortingbyMmlPropertyId } from "../../../utils/propertiesSorting"

// const CREATE_ESTABLISHMENT = gql`
// 	mutation CreateEstablishment(
// 		$discretionalyCorrectionMultiplier: Float = 1.0
// 		$distanceCorrectionMultiplier: Float = 1.0
// 		$costCenterId: IdType = ""
// 		$operatingDistance: Float = 1.0
// 		$propertyId: IdType
// 		$roadCooperativeMemberId: IdType = ""
// 		$roadUsageUnitId: IdType = ""
// 		$amount: Float = 1.0
// 		$additionalInformation: String = ""
// 		$weightCorrectionMultiplier: Float = 1.0
// 	) {
// 		createEstablishment(
// 			input: {
// 				establishment: {
// 					discretionalyCorrectionMultiplier: $discretionalyCorrectionMultiplier
// 					distanceCorrectionMultiplier: $distanceCorrectionMultiplier
// 					lateralDirection: 1
// 					lateralDirectionCorrectionMultiplier: 1
// 					costCenterId: $costCenterId
// 					operatingDistance: $operatingDistance
// 					propertyId: $propertyId
// 					roadCooperativeMemberId: $roadCooperativeMemberId
// 					roadUsageUnitId: $roadUsageUnitId
// 					amount: $amount
// 					additionalInformation: $additionalInformation
// 					weightCorrectionMultiplier: $weightCorrectionMultiplier
// 				}
// 			}
// 		) {
// 			establishment {
// 				propertyId
// 			}
// 			clientMutationId
// 		}
// 	}
// `

export const ESTABLISHMENT_MUTATIONS = gql`
	mutation UpdateEstablishment(
		$additionalInformation: String = ""
		$amount: Float = 1.5
		$discretionalyCorrectionMultiplier: Float = 1.5
		$distanceCorrectionMultiplier: Float = 1.5
		$id: Int = 10
		$operatingDistance: Float = 1.5
		$roadCooperativeMemberId: IdType = ""
		$roadUsageUnitId: IdType = ""
		$weightCorrectionMultiplier: Float = 1.5
		$propertyId: IdType = ""
		$unitizationEstablishmentId: Int
		$from: String
		$type: String
		$unitizationId: IdType = ""
		$costCenterId: IdType = ""
	) {
		updateEstablishmentByIdExtended(
			input: {
				establishmentPatch: {
					additionalInformation: $additionalInformation
					amount: $amount
					discretionalyCorrectionMultiplier: $discretionalyCorrectionMultiplier
					distanceCorrectionMultiplier: $distanceCorrectionMultiplier
					operatingDistance: $operatingDistance
					roadCooperativeMemberId: $roadCooperativeMemberId
					roadUsageUnitId: $roadUsageUnitId
					weightCorrectionMultiplier: $weightCorrectionMultiplier
					propertyId: $propertyId
					unitizationId: $unitizationId
					costCenterId: $costCenterId
				}
				id: $id
				unitizationEstablishmentId: $unitizationEstablishmentId
				from: $from
				type: $type
			}
		) {
			id
		}
	}
`

const GET_ROAD_COOPERATIVE_PROPERTIES_AND_COST_CENTERS = gql`
	query MyQuery {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					id
					name
					propertiesByCostCenterId {
						nodes {
							propertyName
							mmlPropertyId
							propertyTag
							plotId
							id
							costCenterId
						}
					}
				}
			}
		}
	}
`

const GET_ALL_ROAD_USAGE_UNITS = gql`
	query MyQuery2 {
		roadCooperativeWithJWT {
			roadUsageUnitsByRoadCooperativeId {
				nodes {
					id
					roadUsageUnit
					roadUsageUnitType
					weight
				}
			}
		}
	}
`

const CREATE_ROAD_USAGE_UNIT = gql`
	mutation MyMutation2(
		$roadCooperativeId: UUID
		$roadUsageUnit: String = ""
		$roadUsageUnitType: String = ""
		$roadUsageUnitPurpose: UsageUnitPurposeType
		$weight: Float = 1.5
	) {
		createRoadUsageUnit(
			input: {
				roadUsageUnit: {
					roadCooperativeId: $roadCooperativeId
					roadUsageUnit: $roadUsageUnit
					roadUsageUnitType: $roadUsageUnitType
					roadUsageUnitPurpose: $roadUsageUnitPurpose
					weight: $weight
				}
			}
		) {
			roadUsageUnit {
				id
				roadUsageUnit
				roadUsageUnitType
				weight
				roadCooperativeId
			}
		}
	}
`

const GET_ALL_ROADCOOPERTIVE_MEMBERS = gql`
	query MyQuery {
		roadCooperativeWithJWT {
			roadCooperativeMembersByRoadCooperativeId(orderBy: NAME_ASC) {
				nodes {
					userEmail
					name
					id
				}
			}
		}
	}
`

const GET_UPDATE_UNITS_VIEW_TYPE = gql`
	query GetUnitsViewType {
		roadCooperativeWithJWT {
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					id
					unitsPresentationType
				}
			}
		}
	}
`

interface PropertyWithCostCenterName extends Property {
	costCenterName: string
}

interface Unit {
	usageUnitType: string | null
	usageUnit: string | null
	weight: any
	amount: any
	operatingDistance: any
	distanceCorrectionMultiplier: any
	weightCorrectionMultiplier: any
	discretionalyCorrectionMultiplier: any
	roadUnits: any
	MMLpropertyId: string | null
	costCenterId: any
	propertyId: any
}

const CreateEstablishmentDialog = ({
	setShowCreateEstablishmentForm,
	refetchRCGeoJson,
	pulseFunctionWhenDataCreated
}: {
	setShowCreateEstablishmentForm: React.Dispatch<React.SetStateAction<boolean>>
	refetchRCGeoJson: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
	pulseFunctionWhenDataCreated: () => void
}) => {
	const [openAddUnitDialog, setOpenAddUnitDialog] = useState<boolean>(false)

	const [openCreateRoadUsageUnit, setOpenCreateRoadUsgeUnit] = useState<boolean>(false)
	const [usageUnit, setUsageUnit] = useState("")
	const [usageUnitType, setUsageUnitType] = useState("")
	const [weight, setWeight] = useState(0)
	const [usageUnitPurpose, setUsageUnitPurpose] = useState<UsagePurposeType>(null)
	const [allRoadUseUnits, setAllRoadUseUnits] = useState<Unit[]>([])

	const [allMembers, setAllMembers] = useState([])
	const [allProperties, setAllProperties] = useState<PropertyWithCostCenterName[]>([])

	const [selectedMember, setSelectedMember] = useState<number>()

	const { state, dispatch } = useCreateUserState()

	const [selectedPropertyName, setSelectedPropertyName] = useState<string | undefined>("")

	const [openSelectCostCenter, setOpenSelectCostCenter] = useState(false)
	const [costCenterList, setCostCentersList] = useState<Array<{ name: string; id: number }>>([])
	const [selectedCostCenterName, setSelectedCostCenterName] = useState<string | undefined>("")
	const [selectedCostCenterId, setSelectedCostCenterId] = useState<number | null>(null)

	const [unitsViewType, setUnitsViewType] = useState<UnitsViewType>()
	const [addUnitsDisabled, setAddUnitsDisabled] = useState(true)

	const currentUserRC = userProfileStorage({ type: "getActiveRoadCooperative" })

	const {
		data: roadUsageUnits,
		loading: roadUsageUnitsLoading,
		error: roadUsageUnitsError,
		refetch: roadUsageUnitsRefetch
	} = useQuery(GET_ALL_ROAD_USAGE_UNITS)

	useEffect(() => {
		if (roadUsageUnitsLoading || roadUsageUnitsError) {
			setAddUnitsDisabled(true)
		} else {
			if (roadUsageUnits.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId.nodes.length < 1) {
				setAddUnitsDisabled(true)
			} else {
				setAddUnitsDisabled(false)
			}
		}
	}, [roadUsageUnits])

	const { data: usageUnitsViewData } = useQuery(GET_UPDATE_UNITS_VIEW_TYPE, {
		onCompleted(data) {
			setUnitsViewType(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].unitsPresentationType)
		}
	})

	const [createRoadUsageUnit] = useMutation(CREATE_ROAD_USAGE_UNIT)

	const {
		data: rcPropertyData,
		loading: rcPropertyDataLoading,
		error: rcPropertyDataError
	} = useQuery(GET_ROAD_COOPERATIVE_PROPERTIES_AND_COST_CENTERS, {
		onCompleted: (data) => {
			const tempproperties = []
			const costcenters = data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes
			setCostCentersList(costcenters)
			for (let i = 0, len = costcenters.length; i < len; i++) {
				const properties = costcenters[i].propertiesByCostCenterId.nodes
				for (let j = 0, jlen = properties.length; j < jlen; j++) {
					tempproperties.push({
						...properties[j],
						costCenterName: costcenters[i].name
					})
				}
			}
			propertiesSorting(tempproperties, setAllProperties)
		}
	})

	const [createEstablishment] = useMutation(ESTABLISHMENT_MUTATIONS, {
		onCompleted: (data: any) => {
			refetchRCGeoJson()
			pulseFunctionWhenDataCreated()
		}
	})

	useQuery(GET_ALL_ROADCOOPERTIVE_MEMBERS, {
		onCompleted: (data) => {
			setAllMembers(data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes)
		}
	})

	const handleCreateEstablishments = async () => {
		if (!selectedMember) return
		const memberId = selectedMember.toString()
		allRoadUseUnits.map((roaduseUnit: any) => {
			createEstablishment({
				variables: {
					discretionalyCorrectionMultiplier: roaduseUnit.discretionalyCorrectionMultiplier,
					distanceCorrectionMultiplier: roaduseUnit.distanceCorrectionMultiplier,
					costCenterId: roaduseUnit.costCenterId.toString(),
					operatingDistance: parseFloat(roaduseUnit.operatingDistance),
					propertyId: roaduseUnit?.propertyId ?? null,
					roadCooperativeMemberId: memberId,
					roadUsageUnitId: roaduseUnit.usageUnitId.toString(),
					amount: roaduseUnit.amount,
					additionalInformation: roaduseUnit.additionalInformation,
					weightCorrectionMultiplier: roaduseUnit.weightCorrectionMultiplier,
					unitizationId: "",
					unitizationEstablishmentId: null,
					from: "homePage",
					type: "create"
				}
			})
		})

		clearProviderState()

		setShowCreateEstablishmentForm(false)
	}

	const checkEstablishmentValidation = () => {
		let alertMessage = ""
		// if (!state.propertyId) {
		// 	alertMessage = alertMessage + "Kiinteistö puuttuu. "
		// }
		if (!state.usageUnitId && unitsViewType == "professional") {
			alertMessage = alertMessage + "Liikennelaji puuttuu. "
		}
		if (alertMessage != "") {
			toast.warning(alertMessage, { role: "global" })
			return false
		} else {
			return true
		}
	}

	const handleSelectCostCenter = () => {
		if (costCenterList.length == 1) {
			return costCenterList[0].id
		} else {
			setOpenSelectCostCenter(true)
		}

		return 0
	}

	const handleAddRoadUseUnit = async (e: any) => {
		e.preventDefault()
		if (!checkEstablishmentValidation()) return

		// Tässä käsitellään tilanne jossa ei olla valittu kiinteistöä yksikölle.
		// Tässä tapauksessa valitaan pelkkä kustannuspaikka. Normaalisti kustannuspaikka määräytyy kiinteistön mukaan.
		let costCenterId: number = state.MMLpropertyId ? state.costCenterId : selectedCostCenterId ? selectedCostCenterId : 0
		if (!state.MMLpropertyId && !selectedCostCenterId) {
			// Mikäli ei ole valittua kiinteistöä eikä valittua kustannuspaikkaa, niin kutstutaan funktiota mikä valitsee kustannuspaikan
			costCenterId = handleSelectCostCenter()
		}

		if (!state.MMLpropertyId && !costCenterId) {
			// Mikäli ei ole valittuna kiinteistöä eikä kustannuspaikkaa niin ei lisätä yksikköä listaan
			return
		}
		let newUnit: any

		if (unitsViewType == "easy") {
			const roadUsageUnitsList = roadUsageUnits.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId.nodes

			let oletusUsageUnit
			for (let i = 0, len = roadUsageUnitsList.length; len > i; i++) {
				if (roadUsageUnitsList[i].roadUsageUnit == "O" || roadUsageUnitsList[i].roadUsageUnit == "o") {
					oletusUsageUnit = roadUsageUnitsList[i]
					dispatch({ type: "setUsageUnitType", payload: roadUsageUnitsList[i].roadUsageUnitType })
					dispatch({ type: "setUsageUnit", payload: roadUsageUnitsList[i].roadUsageUnit })
					dispatch({ type: "setWeight", payload: roadUsageUnitsList[i].weight })
					dispatch({ type: "setUsageUnitId", payload: roadUsageUnitsList[i].id })
				}
			}
			newUnit = {
				usageUnitType: oletusUsageUnit.roadUsageUnitType,
				usageUnit: oletusUsageUnit.roadUsageUnit,
				weight: oletusUsageUnit.weight,
				usageUnitId: oletusUsageUnit.id,
				amount: state.amount,
				additionalInformation: state.additionalInformation,
				operatingDistance: state.operatingDistance,
				distanceCorrectionMultiplier: state.distanceCorrectionMultiplier,
				weightCorrectionMultiplier: state.weightCorrectionMultiplier,
				discretionalyCorrectionMultiplier: state.discretionalyCorrectionMultiplier,
				roadUnits: state.roadUnits,
				MMLpropertyId: state.MMLpropertyId,
				propertyName: state.propertyName,
				costCenterId: costCenterId,
				propertyId: state.propertyId
			}
		} else {
			newUnit = {
				usageUnitType: state.usageUnitType,
				usageUnit: state.usageUnit,
				weight: state.weight,
				usageUnitId: state.usageUnitId,
				amount: state.amount,
				additionalInformation: state.additionalInformation,
				operatingDistance: state.operatingDistance,
				distanceCorrectionMultiplier: state.distanceCorrectionMultiplier,
				weightCorrectionMultiplier: state.weightCorrectionMultiplier,
				discretionalyCorrectionMultiplier: state.discretionalyCorrectionMultiplier,
				roadUnits: state.roadUnits,
				MMLpropertyId: state.MMLpropertyId,
				propertyName: state.propertyName,
				costCenterId: costCenterId,
				propertyId: state.propertyId
			}
		}
		setAllRoadUseUnits(allRoadUseUnits.concat(newUnit))

		dispatch({ type: "setUsageUnitType", payload: "ULA" })
		dispatch({ type: "setUsageUnit", payload: "Asuinkiinteistö" })
		dispatch({ type: "setWeight", payload: 1 })
		dispatch({ type: "setUsageUnitId", payload: null })
		dispatch({ type: "setAmount", payload: 1 })
		dispatch({ type: "setAdditionalInformation", payload: null })
		dispatch({ type: "setOperatingDistance", payload: 1 })
		dispatch({ type: "setDistanceCorrectionMultiplier", payload: 1 })
		dispatch({ type: "setWeightCorrectionMultiplier", payload: 1 })
		dispatch({ type: "setDiscretionalyCorrectionMultiplier", payload: 1 })
		dispatch({ type: "setRoadUnits", payload: 1 })
		dispatch({ type: "setMMLPropertyId", payload: null })

		clearProviderState()
		setOpenAddUnitDialog(false)
		setOpenSelectCostCenter(false)
		setSelectedCostCenterId(null)
	}

	useEffect(() => {
		const value: number =
			state.weight *
			state.amount *
			state.operatingDistance *
			state.distanceCorrectionMultiplier *
			state.weightCorrectionMultiplier *
			state.discretionalyCorrectionMultiplier

		dispatch({ type: "setRoadUnits", payload: Math.round(value * 1000) / 1000 })
	}, [
		state.weight,
		state.amount,
		state.distanceCorrectionMultiplier,
		state.weightCorrectionMultiplier,
		state.operatingDistance,
		state.discretionalyCorrectionMultiplier
	])

	const clearProviderState = () => {
		dispatch({ type: "setUsageUnitType", payload: "ULA" })
		dispatch({ type: "setUsageUnit", payload: "Asuinkiinteistö" })
		dispatch({ type: "setWeight", payload: 1 })
		dispatch({ type: "setUsageUnitId", payload: null })
		dispatch({ type: "setAmount", payload: 1 })
		dispatch({ type: "setAdditionalInformation", payload: null })
		dispatch({ type: "setEmail", payload: "" })
		dispatch({ type: "setOperatingDistance", payload: 1 })
		dispatch({ type: "setDistanceCorrectionMultiplier", payload: 1 })
		dispatch({ type: "setWeightCorrectionMultiplier", payload: 1 })
		dispatch({ type: "setDiscretionalyCorrectionMultiplier", payload: 1 })
		dispatch({ type: "setRoadUnits", payload: 1 })
		dispatch({ type: "setMMLPropertyId", payload: null })
		dispatch({ type: "setUsageBillingType", payload: null })
		dispatch({ type: "setCompanyName", payload: null })
		dispatch({ type: "setMeetingInviteType", payload: 2 })
		dispatch({ type: "setBusinessId", payload: null })
		dispatch({ type: "setEInvoiceOperator", payload: null })
		dispatch({ type: "setPersonName", payload: null })
		dispatch({ type: "setPersonTel", payload: null })
		dispatch({ type: "setContactPerson", payload: null })
		dispatch({ type: "setAddress", payload: null })
		dispatch({ type: "setPostalCode", payload: null })
		dispatch({ type: "setPostalDistric", payload: null })
		dispatch({ type: "setInvoiceSendingType", payload: 2 })
		dispatch({ type: "setEInvoiceAddress", payload: null })
		dispatch({ type: "setMemberType", payload: null })
		dispatch({ type: "setPropertyId", payload: null })
		dispatch({ type: "setCostCenterId", payload: null })
		setSelectedPropertyName("")
		setSelectedCostCenterName("")
	}

	const handleSetMMLProperty = (value: string) => {
		let mmlPropertyId = null
		let propertyId = null
		let costCenterId = null
		let propertyName = null
		rcPropertyData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.map((cc: any) => {
			return cc.propertiesByCostCenterId.nodes.map((property: Property) => {
				if (!property.id) return
				if (property.id.toString() == value) {
					propertyId = property.id
					mmlPropertyId = property.mmlPropertyId
					costCenterId = cc.id
					propertyName = property.propertyName

					setSelectedPropertyName(property.propertyName)
					setSelectedCostCenterName(cc.name)
				}
			})
		})
		dispatch({ type: "setMMLPropertyId", payload: mmlPropertyId })
		dispatch({ type: "setPropertyId", payload: propertyId })
		dispatch({ type: "setCostCenterId", payload: costCenterId })
		dispatch({ type: "setPropertyName", payload: propertyName })
	}

	const [newUsageUnitType, setNewUsageUnitType] = useState("")
	const [selectedUsageUnitType, setSelectedUsageUnitType] = useState("")
	const [selectedUsageUnitWeight, setSelectedUsageUnitWeight] = useState(0)

	const handleCreateRoadUsageUnit = async () => {
		createRoadUsageUnit({
			variables: {
				roadUsageUnit: usageUnit,
				roadUsageUnitType: usageUnitType,
				roadUsageUnitPurpose: usageUnitPurpose,
				weight: weight,
				roadCooperativeId: currentUserRC.id
			},
			onCompleted: (d) => {
				roadUsageUnitsRefetch().then(() => handleChangeRoadUsageUnit(d.createRoadUsageUnit.roadUsageUnit))
			}
		})
		setOpenCreateRoadUsgeUnit(false)
		const newUsageUnitTypeValue = `${usageUnit} ${usageUnitType} ${weight}`
		setSelectedUsageUnitType(newUsageUnitTypeValue)
		setNewUsageUnitType(newUsageUnitTypeValue)
		setSelectedUsageUnitWeight(weight)
		setUsageUnit("")
		setUsageUnitType("")
		setUsageUnitPurpose(null)
		setWeight(0)
	}

	const handleCloseCreateUsageUnit = () => {
		setOpenCreateRoadUsgeUnit(false)
		setUsageUnit("")
		setUsageUnitType("")
		setUsageUnitPurpose(null)
		setWeight(0)
	}

	const handleChangeSelectedMember = (memberId: number) => {
		setSelectedMember(memberId)
	}

	const handleChangeRoadUsageUnit = (usageUnit: any) => {
		setSelectedUsageUnitType(`${usageUnit.roadUsageUnit} ${usageUnit.roadUsageUnitType} ${usageUnit.weight}`)
		setSelectedUsageUnitWeight(usageUnit.weight)

		dispatch({ type: "setWeight", payload: usageUnit.weight })
		dispatch({ type: "setUsageUnit", payload: usageUnit.roadUsageUnit })
		dispatch({ type: "setUsageUnitType", payload: usageUnit.roadUsageUnitType })
		dispatch({ type: "setUsageUnitId", payload: usageUnit.id })
	}

	const handleSelect = (value: UsagePurposeType) => {
		setUsageUnitPurpose(value)
	}

	return (
		<Container maxWidth="lg">
			<Dialog open={openSelectCostCenter}>
				<DialogCloseButton closeFunction={() => setOpenSelectCostCenter(false)} />
				<CustomDialogTitle>Valitse yksikölle tienosa</CustomDialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column"
						}}
					>
						<Typography>Sinulla on usempi tienosa. Valitse tienosa johon haluat yksikön kuuluvan.</Typography>
						<TextField select onChange={(e: { target: { value: number } }) => setSelectedCostCenterId(e.target.value)} value={selectedCostCenterId}>
							{costCenterList.map((cc) => {
								return (
									<MenuItem key={cc.id} value={cc.id}>
										{cc.name}
									</MenuItem>
								)
							})}
						</TextField>
					</Box>
				</DialogContent>
				<CustomDialogActions>
					<Button
						sx={{
							maxWidth: "120px"
						}}
						fullWidth
						variant="outlined"
						onClick={() => setOpenSelectCostCenter(false)}
					>
						Sulje
					</Button>

					<Button
						sx={{
							maxWidth: "120px"
						}}
						fullWidth
						variant="contained"
						disabled={!selectedCostCenterId}
						onClick={handleAddRoadUseUnit}
					>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>

			<Dialog open={openCreateRoadUsageUnit} maxWidth={"xs"} fullWidth>
				<DialogCloseButton closeFunction={() => setOpenCreateRoadUsgeUnit(false)} />
				<CustomDialogTitle>Luo liikennelaji</CustomDialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column"
						}}
					>
						<TextField
							inputProps={{
								maxLength: 100
							}}
							onChange={(e: { target: { value: string } }) => setUsageUnitType(e.target.value)}
							label="Liikennelaji"
						/>
						<TextField
							inputProps={{
								maxLength: 100
							}}
							onChange={(e: { target: { value: string } }) => setUsageUnit(e.target.value)}
							label="Lyhenne"
						/>
						<MonetaryInput maximumDecimals={2} valueSetter={(v: number) => setWeight(v)} label="Painoluku (tonnia)" value={0} />
						<TextField
							select
							label="Verotuslaji"
							value={usageUnitPurpose ?? 0}
							sx={{ textAlign: "left" }}
							onChange={(e: { target: { value: UsagePurposeType | 0 } }) => handleSelect(e.target.value == 0 ? null : e.target.value)}
						>
							<MenuItem value={0}>Yksityiskäyttö</MenuItem>
							<MenuItem value={"BUSINESS"}>Yritystoiminta</MenuItem>
							<MenuItem value={"AGRICULTURE"}>Maatalous</MenuItem>
							<MenuItem value={"FORESTRY"}>Metsätalous</MenuItem>
						</TextField>
					</Box>
				</DialogContent>
				<CustomDialogActions>
					<Button
						sx={{
							maxWidth: "120px"
						}}
						fullWidth
						variant="outlined"
						onClick={handleCloseCreateUsageUnit}
					>
						Sulje
					</Button>

					<Button
						sx={{
							maxWidth: "120px"
						}}
						fullWidth
						variant="contained"
						onClick={handleCreateRoadUsageUnit}
					>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>

			<Dialog fullWidth open={openAddUnitDialog}>
				<DialogCloseButton closeFunction={() => setOpenAddUnitDialog(false)} />
				<CustomDialogTitle>Lisää uusi yksikkötieto</CustomDialogTitle>
				<DialogContent sx={{ alignSelf: "center" }}>
					<Grid container spacing={2} justifyContent="center" sx={{ maxWidth: "500px" }}>
						{unitsViewType == "professional" ? (
							<>
								<Grid
									item
									xs={12}
									justifyContent="center"
									sx={{
										textAlign: "center"
									}}
								>
									<TextField
										fullWidth
										sx={{ maxWidth: "80%" }}
										label="Kiinteistötunnus"
										select
										variant="outlined"
										onChange={(e: { target: { value: string } }) => handleSetMMLProperty(e.target.value)}
										value={state.propertyId}
									>
										{rcPropertyDataLoading || rcPropertyDataError
											? null
											: allProperties.map((property, index: number) => (
													<Tooltip value={property.id} title={property.costCenterName} key={index}>
														<MenuItem>
															{property.mmlPropertyId} {property.propertyName}
															{property.propertyTag !== 1 && property.propertyTag ? <>/{property.propertyTag}</> : null}
														</MenuItem>
													</Tooltip>
												))}
									</TextField>
									<br />
									{selectedPropertyName ? (
										<>
											<TextField
												inputProps={{
													maxLength: 100
												}}
												variant="filled"
												label="Tilan nimi"
												value={selectedPropertyName}
												disabled
												sx={{ fontSize: "1rem", Width: "200px" }}
											></TextField>

											<TextField
												inputProps={{
													maxLength: 100
												}}
												variant="filled"
												label="Tie"
												value={selectedCostCenterName}
												disabled
												sx={{ fontSize: "1rem", Width: "200px" }}
											></TextField>
										</>
									) : null}
								</Grid>
								<br />
								<Box
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										width: "80%",
										marginLeft: "4%"
									}}
								>
									<TextField
										inputProps={{
											maxLength: 100
										}}
										fullWidth
										variant="outlined"
										sx={{ width: "100%" }}
										id="select-usage-unit-type"
										select
										value={selectedUsageUnitType}
										label="Liikennelaji"
										onChange={(event: React.ChangeEvent<{ value: string }>) => setSelectedUsageUnitType(event.target.value as string)}
									>
										<MenuItem value="">-</MenuItem>
										{roadUsageUnitsLoading || roadUsageUnitsError
											? null
											: roadUsageUnits.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId.nodes.map((t: any) => {
													return (
														<MenuItem
															onClick={(e: any) => handleChangeRoadUsageUnit(t)}
															key={t.id}
															value={`${t.roadUsageUnit} ${t.roadUsageUnitType} ${t.weight}`}
														>
															{t.roadUsageUnit} {t.roadUsageUnitType} {t.weight}
														</MenuItem>
													)
												})}
									</TextField>
									<IconButton onClick={() => setOpenCreateRoadUsgeUnit(true)}>
										<AddIcon />
									</IconButton>
								</Box>
								<Box
									sx={{
										display: "flex",
										maxWidth: "200x",
										width: "100%",
										alignItems: "center",
										justifyContent: "center"
									}}
								></Box>
								<br />
								<TextField disabled type="number" label="Painoluku (t)" variant="outlined" value={selectedUsageUnitWeight} />
								<MonetaryInput
									maximumDecimals={2}
									label="Lukum. (Ha/kpl)"
									variant="outlined"
									valueSetter={(v: number) => dispatch({ type: "setAmount", payload: v })}
									value={state.amount}
								/>
								<br />
								<MonetaryInput
									maximumDecimals={2}
									label="Käyttömatka (km)"
									variant="outlined"
									valueSetter={(v: number) => dispatch({ type: "setOperatingDistance", payload: v })}
									value={state.operatingDistance}
								/>
								<MonetaryInput
									maximumDecimals={2}
									label="Matkan korjauskerroin"
									variant="outlined"
									valueSetter={(v: number) => dispatch({ type: "setDistanceCorrectionMultiplier", payload: v })}
									value={state.distanceCorrectionMultiplier}
								/>
								<br />
								<MonetaryInput
									maximumDecimals={2}
									label="Painoluvun korjauskerroin"
									variant="outlined"
									valueSetter={(v: number) => dispatch({ type: "setWeightCorrectionMultiplier", payload: v })}
									value={state.weightCorrectionMultiplier}
								/>
								<MonetaryInput
									maximumDecimals={2}
									label="Harkinnanvarainen korjauskerroin"
									variant="outlined"
									valueSetter={(v: number) => dispatch({ type: "setDiscretionalyCorrectionMultiplier", payload: v })}
									value={state.discretionalyCorrectionMultiplier}
								/>
								<br />
								<Grid sx={{ padding: "0px" }} item justifyContent="center">
									<TextField
										inputProps={{
											maxLength: 500
										}}
										type="text"
										label="Lisätietoa"
										multiline
										rows={4}
										variant="outlined"
										sx={{ maxWidth: "218px" }}
										fullWidth
										onChange={(e: any) => dispatch({ type: "setAdditionalInformation", payload: e.target.value })}
										value={state.additionalInformation}
									/>
									<br />
									<TextField label="Tieyksiköt" variant="filled" value={state.roadUnits} disabled /> <br />
								</Grid>
							</>
						) : (
							<>
								<Grid
									item
									xs={12}
									justifyContent="center"
									sx={{
										textAlign: "center"
									}}
								>
									<TextField
										fullWidth
										sx={{ maxWidth: "80%" }}
										label="Kiinteistötunnus"
										select
										variant="outlined"
										onChange={(e: { target: { value: string } }) => handleSetMMLProperty(e.target.value)}
										value={state.propertyId}
									>
										{rcPropertyDataLoading || rcPropertyDataError
											? null
											: allProperties.map((property, index: number) => (
													<Tooltip value={property.id} title={property.costCenterName} key={index}>
														<MenuItem>
															{property.mmlPropertyId} {property.propertyName}
															{property.propertyTag !== 1 && property.propertyTag ? <>/{property.propertyTag}</> : null}
														</MenuItem>
													</Tooltip>
												))}
									</TextField>
									<br />
									{selectedPropertyName ? (
										<>
											<TextField
												inputProps={{
													maxLength: 100
												}}
												variant="filled"
												label="Tilan nimi"
												value={selectedPropertyName}
												disabled
												sx={{ fontSize: "1rem", maxWidth: "40%" }}
											></TextField>

											<TextField
												inputProps={{
													maxLength: 100
												}}
												variant="filled"
												label="Tie"
												value={selectedCostCenterName}
												disabled
												sx={{ fontSize: "1rem", maxWidth: "40%" }}
											></TextField>
										</>
									) : null}
								</Grid>
								<Grid
									item
									xs={12}
									justifyContent="center"
									sx={{
										textAlign: "center",
										maxWidth: "80%"
									}}
								>
									<MonetaryInput
										maximumDecimals={2}
										label="Yksiköt"
										variant="outlined"
										valueSetter={(v: number) => dispatch({ type: "setAmount", payload: v })}
										value={state.amount}
										baseProps={{ sx: { maxWidth: "40%" } }}
									/>

									<TextField
										inputProps={{
											maxLength: 500
										}}
										type="text"
										label="Lisätietoa"
										multiline
										maxRows={4}
										variant="outlined"
										sx={{ maxWidth: "40%" }}
										fullWidth
										onChange={(e: any) => dispatch({ type: "setAdditionalInformation", payload: e.target.value })}
										value={state.additionalInformation}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</DialogContent>
				<CustomDialogActions>
					<Button variant="outlined" onClick={() => setOpenAddUnitDialog(false)}>
						Sulje
					</Button>
					<Button variant="contained" onClick={handleAddRoadUseUnit}>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>

			<Grid container spacing={5}>
				<Grid sx={{ textAlign: "center" }} item xs={12} justifyContent="center">
					<TextField
						label="Valitse osakas"
						select
						fullWidth
						value={selectedMember}
						onChange={(e: { target: { value: string } }) => handleChangeSelectedMember(Number(e.target.value))}
						sx={{
							maxWidth: "400px"
						}}
					>
						{allMembers.map((member: RoadCooperativeMemberType, index: number) => {
							return (
								<MenuItem key={index} value={member.id}>
									{member.name}
								</MenuItem>
							)
						})}
					</TextField>
				</Grid>
				<Divider></Divider>

				<Grid sx={{ textAlign: "center" }} item xs={12} justifyContent="center">
					<Tooltip title={!addUnitsDisabled ? "" : "Suorita Tievahdin käyttöönotto ensin."}>
						<Button
							sx={{ maxWidth: "250px" }}
							disabled={selectedMember && !addUnitsDisabled ? false : true}
							fullWidth
							variant="contained"
							onClick={() => setOpenAddUnitDialog(true)}
						>
							Lisää yksiköitä
						</Button>{" "}
					</Tooltip>
					<br />
					<br />
				</Grid>
				<Divider></Divider>

				<Grid xs={12} item sx={{ textAlign: "center" }} alignItems="center" justifyContent="center">
					{allRoadUseUnits.length == 0 ? null : (
						<AllRoadUseUnitsList
							unitsViewType={unitsViewType}
							allRoadUseUnits={allRoadUseUnits}
							setAllRoadUseUnits={setAllRoadUseUnits}
							costCenterList={costCenterList}
						/>
					)}
				</Grid>
			</Grid>

			<Button color="primary" onClick={handleCreateEstablishments} variant="contained" sx={{ position: "absolute", bottom: "25px", right: "25px" }}>
				Tallenna
			</Button>
		</Container>
	)
}

const AllRoadUseUnitsList = (props: any) => {
	const handleRemoveUnit = (i: any) => {
		const newUnits = props.allRoadUseUnits.filter((u: any, index: number) => index !== i)
		props.setAllRoadUseUnits(newUnits)
	}

	return (
		<div>
			<TableContainer component={Paper} sx={{ marginBottom: "50px" }}>
				<Table>
					<TableHead>
						<TableRow>
							{props.unitsViewType == "easy" ? null : <TableCell sx={{ width: "20px" }}></TableCell>}
							{props.costCenterList.length <= 1 ? null : <TableCell sx={{ width: "20px" }}>Tie</TableCell>}
							<TableCell align="center">Tilan nimi</TableCell>
							<TableCell align="center">Kiinteistötunnus</TableCell>
							<TableCell aling="center">Lisätieto</TableCell>
							<TableCell align="center">Tieyksiköt</TableCell>
							<TableCell sx={{ width: "20px" }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.allRoadUseUnits.map((unit: any, index: number) => {
							return (
								<>
									<Row
										unit={unit}
										key={index}
										unitsViewType={props.unitsViewType}
										index={index}
										handleRemoveUnit={handleRemoveUnit}
										costCenterList={props.costCenterList}
									/>
								</>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

const Row = ({ unit, handleRemoveUnit, index, unitsViewType, costCenterList }: any) => {
	const [open, setOpen] = useState(false)
	const costCenterName = costCenterList.find((cc: any) => cc.id == unit.costCenterId)?.name

	return (
		<>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				{unitsViewType == "easy" ? null : (
					<TableCell>
						<IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
					</TableCell>
				)}

				{costCenterList.length <= 1 ? null : <TableCell align="center">{costCenterName}</TableCell>}

				<TableCell align="center">{unit.propertyName}</TableCell>
				<TableCell align="center">{unit.MMLpropertyId}</TableCell>
				<TableCell align="center">{unit.additionalInformation}</TableCell>
				<TableCell align="center">{unit.roadUnits}</TableCell>
				<TableCell>
					<Tooltip title="Poista yksikkörivi">
						<IconButton onClick={() => handleRemoveUnit(index)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</TableCell>
			</TableRow>
			{unitsViewType == "easy" ? null : (
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Box sx={{ margin: 1 }}>
								<Typography sx={{ fontSize: "1.2rem" }}>Yksikkötiedot</Typography>
								<Table size="small" aria-label="purchases">
									<TableHead>
										<TableRow>
											<TableCell>
												<Typography sx={{ fontSize: "1.0rem" }}>Liikennelaji</Typography>
											</TableCell>
											<TableCell>
												<Typography sx={{ fontSize: "1.0rem" }}>Lyhenne</Typography>
											</TableCell>
											<TableCell>
												<Typography sx={{ fontSize: "1.0rem" }}>Lukumäärä (ha/kpl)</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>{unit.usageUnitType}</TableCell>
											<TableCell>{unit.usageUnit} </TableCell>
											<TableCell>{unit.amount.toFixed(2)} </TableCell>
										</TableRow>
									</TableBody>
									<TableRow>
										<TableCell>
											<Typography sx={{ fontSize: "1.0rem" }}>Käyttömatka (km)</Typography>
										</TableCell>
										<TableCell>
											<Typography sx={{ fontSize: "1.0rem" }}>Korjauskertoimet</Typography>
										</TableCell>
										<TableCell></TableCell>
									</TableRow>
									<TableBody>
										<TableRow>
											<TableCell>{unit.operatingDistance.toFixed(2)}</TableCell>
											<TableCell>
												{(
													unit.distanceCorrectionMultiplier *
													unit.weightCorrectionMultiplier *
													unit.discretionalyCorrectionMultiplier
												).toFixed(2)}
											</TableCell>
											<TableCell></TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	)
}

export default CreateEstablishmentDialog
