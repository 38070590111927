import React, { useEffect, useRef, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"

import { Paper, Typography, Tooltip, IconButton } from "@mui/material"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
import { toast } from "react-toastify"
import { Button } from "@mui/material"
import { keyframes } from "@emotion/react"
import HelpIcon from "@mui/icons-material/Help"

import { Box } from "@mui/material"
import { Backdrop } from "@mui/material"
import { Divider } from "@mui/material"
import DialogCloseButton from "./DialogCloseButton"
import { Fullscreen } from "@mui/icons-material"
import { Dialog } from "@mui/material"
import CustomDialogTitle from "./CustomDialogTitle"
import { DialogContent } from "@mui/material"
import CustomDialogActions from "./CustomDialogActions"

const CREATE_SETUP_HELP = gql`
	mutation CreateSetUpHelp($input: CreateSetUpHelpInput!) {
		createSetUpHelp(input: $input) {
			success
		}
	}
`

const GET_ROAD_COOPERATIVE_DATA = gql`
	query GetRoadCooperativeData {
		roadCooperativeWithJWT {
			id
			askedHelp
			costCentersByRoadCooperativeId {
				nodes {
					id
					establishmentsByCostCenterId {
						nodes {
							id
						}
						totalCount
					}
					propertiesByCostCenterId {
						nodes {
							id
						}
						totalCount
					}
				}
			}
			roadCooperativeMembersByRoadCooperativeId {
				nodes {
					id
				}
				totalCount
			}
			totalUnits
		}
	}
`

// type RCInfoDataType = {
// 	members: number
// 	establishments: number
// 	properties: number
// 	totalUnits: number
// }

interface PopUpForIntroductionSupportProps {
	popUpText: string
	title: string
	top: number
	left: number
	pointerDirection: "left" | "right" | "bottom" | "top" | "none"
	subclass: string
	notOpenHelp?: boolean
	iconLeft?: string
	paperWidth?: string
}

const PopUpForIntroductionSupport: React.FC<PopUpForIntroductionSupportProps> = ({
	popUpText,
	title,
	top,
	left,
	pointerDirection,
	iconLeft,
	subclass,
	notOpenHelp,
	paperWidth
}) => {
	const [createSetUpHelp, { loading }] = useMutation(CREATE_SETUP_HELP)
	const currentRC = userProfileStorage({ type: "getActiveRoadCooperative" })
	const [open, setOpen] = useState(notOpenHelp == true ? false : true)
	const [showThankYouMessage, setShowThankYouMessage] = useState(false)
	const [position, setPosition] = useState({ top: 0, left: 0 })
	const buttonRef = useRef<HTMLButtonElement>(null)
	const [askedHelp, setAskedHelp] = useState(false)
	const [allCompleted, setAllCompleted] = useState(false)
	const [openWarning, setOpenWarning] = useState(false)

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	})

	const roadCooperativeName = currentRC.roadCooperative

	const {
		data: data,
		error: error,
		loading: loadingData,
		refetch: refetch
	} = useQuery(GET_ROAD_COOPERATIVE_DATA, {
		onError(error) {
			console.log(error)
		}
	})

	const checkUpdateAllStatus = (data: any): boolean => {
		const ccExists = data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length > 0
		const membersExist = data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.totalCount > 0
		const unitsExist = data.roadCooperativeWithJWT.totalUnits > 0

		const RCInfoDataObj = {
			members: data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.totalCount,
			establishments: ccExists
				? data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.reduce(
						(acc: number, cc: any) => acc + cc.establishmentsByCostCenterId.totalCount,
						0
					)
				: 0,
			properties: ccExists
				? data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.reduce(
						(acc: number, cc: any) => acc + cc.propertiesByCostCenterId.totalCount,
						0
					)
				: 0,
			totalUnits: data.roadCooperativeWithJWT.totalUnits
		}

		const allCompleted = ccExists && membersExist && unitsExist

		const allStatusTemp: AllStatusType = allCompleted
			? {
					roadCreated: "onCompleted",
					membersCreated: "onCompleted",
					establishmentsCreated: "onCompleted"
				}
			: {
					roadCreated: ccExists ? "onCompleted" : "onProgress",
					membersCreated: membersExist ? "onCompleted" : "onWaiting",
					establishmentsCreated: unitsExist ? "onCompleted" : "onWaiting"
				}

		return allCompleted
	}

	const handleSendSetUp = async () => {
		try {
			const response = await createSetUpHelp({ variables: { input: { roadCooperativeName } } })
			if (response.data.createSetUpHelp.success) {
				toast.success(response.data.createSetUpHelp.message)
				setOpen(false)
				setOpenWarning(false)
				setShowThankYouMessage(true)
				setTimeout(() => setShowThankYouMessage(false), 5000)
			} else {
				toast.error("Virhe pyynnön lähettämisessä: " + response.data.createSetUpHelp.message)
			}
		} catch (error: any) {
			toast.error("Virhe pyynnön lähettämisessä: " + error.message)
		}
		refetch()
		setAskedHelp(data.roadCooperativeWithJWT.askedHelp)
	}

	const handleHelpClick = () => {
		if (buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect()
			setPosition({
				top: rect.bottom + top,
				left: rect.left + left
			})
			setOpen(true)
		}
		console.log(askedHelp)
	}

	useEffect(() => {
		refetch()

		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])
	useEffect(() => {
		if (loadingData) return
		if (error) {
			console.log(error)
		}
		setAskedHelp(data.roadCooperativeWithJWT.askedHelp)
		const completed = checkUpdateAllStatus(data)
		setAllCompleted(completed)
	}, [data])

	useEffect(() => {
		if (buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect()
			console.log(rect)
			setPosition({
				top: rect.bottom + top,
				left: rect.left + left
			})
		}
	}, [windowSize])

	const handleClose = () => {
		setOpen(false)
	}

	const fadeInUp = keyframes`
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `

	const fadeOutDown = keyframes`
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100px);
      opacity: 0;
    }
  `
	const pulseAnimation = keyframes`
0% {
  transform: scale(1);
}
25% {
	transform: scale(1.17);
	}
50% {
	transform: scale(1.17);
}
75% {
	transform: scale(1.17);
	
}
100% {
  transform: scale(1);
}
`

	return !askedHelp && !allCompleted ? (
		<>
			<Tooltip sx={{ marginLeft: "5px" }} title="Pyydä apua Tievahdin käyttöönottoon.">
				<IconButton ref={buttonRef} sx={{ bottom: "2px", marginBottom: "-6px", left: iconLeft || "0px" }} onClick={handleHelpClick}>
					<HelpIcon color="secondary" sx={{ animation: `${pulseAnimation} 2s ease-in-out infinite` }} />
				</IconButton>
			</Tooltip>
			{open && (
				<Paper
					className={`onboarding-helper ${pointerDirection == "none" ? null : "onboarding-helper-arrow"} ${pointerDirection} ${subclass}`}
					sx={{
						// position: "fixed", // Asettaa Paperin kiinteäksi suhteessa selainikkunaan
						// top: top, // Keskittää pystysuunnassa
						// left: left, // Keskittää vaakasuunnassa
						position: "absolute",
						top: `${position.top}px`,
						left: `${position.left}px`,
						transform: "translate(-50%, -50%)", // Tuo elementin tarkan keskikohdan ikkunaan
						zIndex: 1300, // Nostaa elementin muiden päälle (muihin komponentteihin verrattuna)
						padding: 2, // Lisää tyhjää tilaa sisältöön
						bgcolor: "background.paper", // Asettaa taustavärin teemasta
						boxShadow: 3, // Lisää varjon, jotta komponentti erottuu
						maxWidth: paperWidth || "600px",
						minWidth: paperWidth || "600px"
					}}
				>
					<DialogCloseButton closeFunction={() => setOpen(false)} />
					<Typography textAlign="center" sx={{ fontWeight: "900", fontSize: "20px", height: "30px" }}>
						{title}
					</Typography>
					<br></br>
					<Divider sx={{ my: 2 }} />
					<Box sx={{ mb: 3 }} dangerouslySetInnerHTML={{ __html: popUpText }} />
					<Divider sx={{ my: 2 }} />

					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							gap: 2,
							mt: 3
						}}
					>
						<Button onClick={handleClose} color="secondary" variant="outlined">
							Sulje
						</Button>
						<Button
							onClick={() => {
								setOpenWarning(true)
							}}
							color="primary"
							variant="contained"
							disabled={loading}
						>
							Lähetä pyyntö
						</Button>
					</Box>
				</Paper>
			)}

			{showThankYouMessage && (
				<Paper
					elevation={5}
					sx={{
						zIndex: 3,
						position: "fixed",
						left: "calc(50vw - 100px)",
						top: "calc(100vh - 130px)",
						width: "200px",
						padding: "25px",
						animation: `${fadeInUp} 1s, ${fadeOutDown} 1s 4s`
					}}
				>
					<Typography color="primary" sx={{ textAlign: "center" }}>
						Kiitos yhteydenotosta. Palaamme asiaan pian.
					</Typography>
				</Paper>
			)}

			<Dialog open={openWarning}>
				<CustomDialogTitle>Pyydä apua</CustomDialogTitle>
				<DialogCloseButton closeFunction={() => setOpenWarning(false)} />
				<DialogContent>Haluatko varmasti pyytää apua tievahdin käyttöönottoon?</DialogContent>
				<CustomDialogActions>
					<Button variant="outlined" color="cancel" onClick={() => setOpenWarning(false)}>
						Peruuta
					</Button>
					<Button onClick={handleSendSetUp} color="primary" variant="contained" disabled={loading}>
						{loading ? "Lähetetään..." : "Lähetä pyyntö"}
					</Button>
				</CustomDialogActions>
			</Dialog>
		</>
	) : null
}
export default PopUpForIntroductionSupport
