import { Stack } from "@mui/material"
import { Button } from "@mui/material"
import { Typography } from "@mui/material"
import React from "react"

interface Props {
	municipalityName?: string
	handleDisabledMunicipalityLogoClick: () => void
}

const MunicipalityButtonDisabled: React.FC<Props> = ({ municipalityName, handleDisabledMunicipalityLogoClick }: any) => {
	const vaakunaSrc = `/municipalityLogos/${encodeURIComponent(municipalityName)}.png`

	return (
		<Button
			onClick={handleDisabledMunicipalityLogoClick}
			color="primary"
			variant="contained"
			onMouseEnter={(e: { currentTarget: { style: { transform: string } } }) => (e.currentTarget.style.transform = "scale(1.05)")}
			onMouseLeave={(e: { currentTarget: { style: { transform: string } } }) => (e.currentTarget.style.transform = "scale(1)")}
			sx={{
				position: "fixed",
				cursor: "pointer",
				backgroundColor: "#c5c1c1",
				minWidth: "auto",
				height: "47px",
				marginTop: "10px",
				marginRight: "5px",
				right: "300px",
				paddingRight: "20px",
				paddingLeft: "50px",
				zIndex: "1000",
				transition: "transform 0.3s ease-in-out",
				"&:hover": {
					filter: "brightness(90%)",
					backgroundColor: "#c5c1c1"
				},
				"@media screen and (max-width: 900px)": {
					display: "none"
				}
			}}
		>
			<Stack direction="row" sx={{ width: "auto", height: "48px", alignItems: "center" }}>
				<img
					src={vaakunaSrc}
					style={{
						width: "60px",
						height: "60px",
						objectFit: "contain",
						position: "absolute",
						left: "-15px", // Säädä tätä arvoa tarvittaessa
						filter: "grayscale(100%)"
					}}
					alt={municipalityName}
				/>
				<Typography
					sx={{
						color: "white",
						fontWeight: "700",
						fontSize: "0.8rem",
						display: "inline"
					}}
				>
					{municipalityName}
				</Typography>
			</Stack>
		</Button>
	)
}

export default MunicipalityButtonDisabled
