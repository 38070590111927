import React, { useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { Button, Dialog, Tooltip, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import coinsImg from "../../resources/icons/coins.png"
import { pulseAnimation } from "../../utils/animations"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import DialogCloseButton from "../reusables/DialogCloseButton"
import CustomDialogActions from "../reusables/CustomDialogActions"
import { DialogContent } from "@mui/material"

const GET_BANK_ACCOUNTS_AND_TOTAL_UNITS = gql`
	query GetBankAccounts {
		roadCooperativeWithJWT {
			id
			totalUnits
			bankAccountsByRoadCooperativeId {
				nodes {
					id
					name
					bankAccountNumber
					balance
				}
			}
			flBankAccountsByRoadCooperativeId {
				nodes {
					id
					bankAccountNumber
					name
					balance
				}
			}
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					financesVersion
				}
			}
		}
	}
`

const nf = new Intl.NumberFormat("fi-FI", { style: "currency", currency: "EUR" })

const BankBalanceBlock = () => {
	const [totalBalance, setTotalBalance] = useState<number>(0)
	const [totalUnits, setTotalUnits] = useState(0)
	const [openRCAccountPopUp, setOpenRCAccountPopUp] = useState(false)
	const { loading, data } = useQuery(GET_BANK_ACCOUNTS_AND_TOTAL_UNITS)

	const navigate = useNavigate()

	useEffect(() => {
		if (loading || !data) return

		const financesVersion = data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].financesVersion

		const accounts =
			financesVersion === "FULL"
				? data.roadCooperativeWithJWT.bankAccountsByRoadCooperativeId.nodes
				: data.roadCooperativeWithJWT.flBankAccountsByRoadCooperativeId.nodes

		let total = 0
		for (let i = 0; i < accounts.length; i++) {
			total += +accounts[i].balance
		}

		setTotalBalance(Math.round(total * 100) / 100)
		setTotalUnits(data.roadCooperativeWithJWT.totalUnits ?? 0)
	}, [data, loading])

	const hasAccounts =
		data?.roadCooperativeWithJWT?.bankAccountsByRoadCooperativeId?.nodes?.length !== 0 ||
		data?.roadCooperativeWithJWT?.flBankAccountsByRoadCooperativeId?.nodes?.length !== 0

	return (
		<>
			<Dialog sx={{ zIndex: "1002" }} open={openRCAccountPopUp}>
				<CreateRCBankAccount setOpenRCAccountPopUp={setOpenRCAccountPopUp} />
			</Dialog>
			{hasAccounts && (
				<Button
					onClick={() => navigate("/finances")}
					color="primary"
					variant="contained"
					onMouseEnter={(e: { currentTarget: { style: { transform: string } } }) => (e.currentTarget.style.transform = "scale(1.05)")}
					onMouseLeave={(e: { currentTarget: { style: { transform: string } } }) => (e.currentTarget.style.transform = "scale(1)")}
					sx={{
						position: "fixed",
						cursor: "pointer",
						width: "150px",
						height: "47px",
						marginTop: "10px",
						right: "130px",
						zIndex: "1000",
						transition: "transform 0.3s ease-in-out",
						"@media screen and (max-width: 630px)": {
							display: "none"
						}
					}}
				>
					<Stack direction="row" sx={{ width: "150px", height: "48px", alignItems: "center" }}>
						<img
							src={coinsImg}
							style={{
								width: "70%",
								height: "125%",
								objectFit: "contain",
								right: "75px",
								position: "absolute"
							}}
						/>
						<Typography
							sx={{
								color: "white",
								fontWeight: "700",
								fontSize: "0.875rem",
								marginLeft: "45px",
								marginRight: "auto"
							}}
						>
							{nf.format(totalBalance)}
						</Typography>
					</Stack>
				</Button>
			)}

			{!hasAccounts && (
				<Tooltip title="Tilin saldo" sx={{ zIndex: "1002" }}>
					<Button
						onClick={() => {
							setOpenRCAccountPopUp(true)
						}}
						color="primary"
						variant="contained"
						onMouseEnter={(e: { currentTarget: { style: { transform: string } } }) => (e.currentTarget.style.transform = "scale(1.05)")}
						onMouseLeave={(e: { currentTarget: { style: { transform: string } } }) => (e.currentTarget.style.transform = "scale(1)")}
						sx={{
							position: "fixed",
							cursor: "pointer",
							width: "150px",
							height: "47px",
							marginTop: "10px",
							right: "130px",
							zIndex: "1000",
							backgroundColor: "#c5c1c1",
							transition: "all 0.2s, transform 0.3s ease-in-out",
							animation: totalUnits > 0 ? `${pulseAnimation} 2s infinite` : "none",
							"@media screen and (max-width: 630px)": {
								display: "none"
							},
							"&:hover": {
								filter: "brightness(90%)",
								backgroundColor: "#c5c1c1"
							}
						}}
					>
						<Stack
							direction="row"
							sx={{
								width: "150px",
								height: "48px",
								alignItems: "center"
							}}
						>
							<img
								src={coinsImg}
								style={{
									width: "70%",
									height: "125%",
									objectFit: "contain",
									right: "75px",
									position: "absolute",
									filter: "grayscale(90%)"
								}}
							/>
							<Typography
								sx={{
									color: "white",
									fontWeight: "700",
									fontSize: "0.875rem",
									marginLeft: "45px",
									marginRight: "auto"
								}}
							>
								{nf.format(totalBalance)}
							</Typography>
						</Stack>
					</Button>
				</Tooltip>
			)}
		</>
	)
}

const CreateRCBankAccount = ({ setOpenRCAccountPopUp }: any) => {
	const navigate = useNavigate()

	const handlePaperClick = () => {
		navigate("/finances")
	}

	return (
		<>
			<CustomDialogTitle>Pankkitilin saldo</CustomDialogTitle>
			<DialogCloseButton closeFunction={() => setOpenRCAccountPopUp(false)} />
			<DialogContent sx={{ textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center" }}>
				Jotta saatte tiekuntasi pankkitilin saldon näkyviin, sinun täytyy aktivoida talousosio käyttöön.
			</DialogContent>
			<CustomDialogActions>
				<Button
					onClick={() => {
						setOpenRCAccountPopUp(false)
					}}
					variant="outlined"
				>
					Sulje
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						handlePaperClick()
					}}
				>
					Aktivoi
				</Button>
			</CustomDialogActions>
		</>
	)
}

export default BankBalanceBlock
