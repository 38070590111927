import { Collapse } from "@mui/material"
import { SxProps } from "@mui/system"
import { Typography } from "@mui/material"
import { Alert, AlertTitle } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Tooltip } from "@mui/material"
import MapIcon from "@mui/icons-material/Map"

import { IconButton } from "@mui/material"
import PopUpForIntroductionSupport from "./PopUpForIntroductionSupport"

interface NotificationProps {
	severity: "info" | "error" | "warning"
	children?: React.ReactNode
	title?: string
	autoClose?: number
	sx?: SxProps
	TypographyProps?: SxProps
	CollapseProps?: SxProps
	notOpenHelp?: boolean
}

const BiggerNotification = ({ severity, children, title, autoClose, sx, TypographyProps, CollapseProps, notOpenHelp }: NotificationProps) => {
	const [openAlert, setOpenAlert] = useState(true)

	useEffect(() => {
		if (autoClose) {
			setTimeout(() => {
				setOpenAlert(false)
			}, autoClose)
		}
	}, [])

	const handleInfoClick = () => {
		const url = "https://storymaps.arcgis.com/stories/04cefb6993254e5ab4fdec5bc0f129cb"
		const link = document.createElement("a")
		link.href = url
		;(link.rel = "noreferrer"), (link.target = "_blank"), link.click()
	}

	return (
		<>
			<Collapse sx={CollapseProps} in={openAlert}>
				<Alert onClose={() => setOpenAlert(false)} severity={severity} sx={sx}>
					{title ? <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle> : null}
					<Typography sx={{ fontWeight: "700", TypographyProps }}>
						{children}

						<Tooltip title="Klikkaamalla pääset metsäkeskuksen tiekuntakarttaan josta voit hakea oman tiekuntasi vaikutusalueen">
							<IconButton sx={{ marginLeft: "10px", padding: "0px" }} onClick={handleInfoClick}>
								<MapIcon sx={{ fontSize: 25, color: "#0288d1", padding: "0px" }} />
							</IconButton>
						</Tooltip>
						<PopUpForIntroductionSupport
							pointerDirection="top"
							subclass="ob-after-properties"
							paperWidth="400px"
							top={85}
							left={-4}
							title={"Pyydä apua Tievahdin käyttöönottoon."}
							popUpText={`Mikäli tarvitset apua Tievahdin käyttöönotossa, autamme mielellämme. Lisäämme puolestanne tiekunnan tien, kiinteistöt, osakkaat sekä yksiköt
					olemassa olevien tietojenne pohjalta. Palvelun hinta on <b>250 € (alv 0 %)</b>.`}
							notOpenHelp={notOpenHelp}
						></PopUpForIntroductionSupport>
					</Typography>
				</Alert>
			</Collapse>
		</>
	)
}

export default BiggerNotification
